import React from 'react'
import wonImg from '../Assets/won.png'
const CashOut = ({ cashOutAmount, cashOutMultiplier }) => {
  return (<div className="absolute self-center mt-[300px] flex flex-col justify-center items-center p-3 w-[60vw] md:w-[15vw] bg-gray-800 rounded-lg">
    <p className="font-bold text-2xl p-2 text-green-500">You Won</p>
    <div className="flex justify-center items-center rounded-lg bg-gray-900 w-[35vw] md:w-[12vw]">
       <img className="w-[50px] h-[50px]" src={wonImg} alt='Won image'/> 
    <div className="flex flex-col justify-center items-center p-2  space-y-1">
      <p className="font-bold text-2xl text-gray-400 ">x{cashOutMultiplier}</p>
      <p className="font-bold text-green-500 ">${cashOutAmount.toFixed(2)}</p>
    </div>
    </div>
  </div>
  )
}

export default CashOut