import React, { useState, useEffect, useRef } from "react";
import MinesControls from "./MinesControls";
import diamondSound from '../Assets/diamond.wav'
import bombSound from '../Assets/bomb.wav'

import CashOut from './CashOut'


const MinesGame = () => {
  const [isBetting, setIsBetting] = useState(false)
  const [grid, setGrid] = useState([]);
  const [revealed, setRevealed] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [mines, setMines] = useState(1); // Default number of mines
  const [tilesRevealed, setTilesRevealed] = useState(0); // Tracks revealed tiles
  const [payoutMultiplier, setPayoutMultiplier] = useState(); // Initial multiplier
  const [cashedOut, setCashedOut] = useState(false)
  const [betAmount, setBetAmount] = useState(50)

  // Base multiplier
  const baseMultiplier = 1.00;

  const playBombSound = () => {
    const audio = new Audio(bombSound);
    audio.play();
  };

  const playDiamondSound = () => {
    const audio = new Audio(diamondSound);
    audio.play();
  };
  const totalTiles = 25; // 5x5 grid
  const totalMines = mines;  // 5 mines
  const safePicks = revealed.filter(Boolean).length;   // 3 safe picks made
  let previousMultiplier = 1


  // Update payout multiplier
  function calculateMultiplier() {
    // Validate inputs
    if (safePicks < 0 ) {
        throw new Error("Invalid number of safe picks.");
    }
    const tiles = totalTiles - mines
    const payout = totalTiles/(tiles - safePicks)
    const payoutMultiplier= previousMultiplier * payout  

    return payoutMultiplier.toFixed(2)
}

  const initializeGrid = () => {
    setCashedOut(false)
    setCashOutAmount(0)
    setCashOutMultiplier(0)
    setIsBetting(true)
    const size = 5; // 5x5 grid
    const totalTiles = size * size;

    // Initialize grid with diamonds
    const newGrid = Array(totalTiles).fill("diamond");

    // Place mines randomly
    for (let i = 0; i < mines; i++) {
      let index;
      do {
        index = Math.floor(Math.random() * newGrid.length);
      } while (newGrid[index] === "bomb");
      newGrid[index] = "bomb";
    }

    setGrid(newGrid);
    setRevealed(Array(totalTiles).fill(false));
    setGameOver(false);
    setTilesRevealed(0);
    setPayoutMultiplier(baseMultiplier); // Reset multiplier
  };
  const [history, setHistory] = useState([]);
  const [cashOutAmount, setCashOutAmount] = useState(0)
  const [cashOutMultiplier, setCashOutMultiplier] = useState(1)
  const cashOut =()=>{
    if(safePicks > 0 ){
      setCashOutAmount(betAmount * payoutMultiplier)
      setCashOutMultiplier(payoutMultiplier)
      setCashedOut(true)
      updateHistory(payoutMultiplier)
      setPayoutMultiplier(1.00)
      
    }
  }
  const handleClick = (index, payout=payoutMultiplier) => {
    if (gameOver || revealed[index]) return;
    if (cashedOut) return;
    const updatedRevealed = [...revealed];
    updatedRevealed[index] = true;

    if (grid[index] === "bomb") {
      setGameOver(true);
      updateHistory('0.00')
      const updatedRevealed = [...revealed].map(()=> true)
      // updatedRevealed[index] = true;
      setRevealed(updatedRevealed);
      playBombSound()
      setIsBetting(false)
    //   const updatedHistory = [...history];
    // updatedHistory[payout] = true;
    // setHistory(updatedHistory)
    } else {
      const updatedRevealed = [...revealed];
      playDiamondSound()
      updatedRevealed[index] = true;
      setRevealed(updatedRevealed);
      previousMultiplier = calculateMultiplier()
      setPayoutMultiplier(calculateMultiplier()); // Update multiplier
    }

    setRevealed(updatedRevealed);
  };
  const nums = [revealed.map((exist, index)=> index)]
  const pickRandomTile =(excludedTiles=revealed)=>{
    console.log(nums)
    // let min = 1
    // let max =24
    let randomTile ;
    randomTile = Math.floor(Math.random() * 24) + 1
    if(!revealed[`${randomTile}`]){

      handleClick(randomTile)
    }
    // randomTile = Math.floor(Math.random() * (max - min)) + min
    // do{
    //   handleClick(randomTile)
    // }while(excludedTiles.includes(randomTile));

    // console.log(excludedTiles)
  }

  function setNewAmount(operation){
    if(operation === 'x2'){
      const amt = betAmount * 2 
      setBetAmount(amt)
      }
      if(operation === '/2'){
        const amt = betAmount / 2 
        setBetAmount(amt)
        }
  }
  const cashDisplay = payoutMultiplier * betAmount
  const scrollContainerRef = useRef(null);
  useEffect(()=>{
    if(scrollContainerRef.current){
      const scrollWidth = scrollContainerRef.current.scrollwidth
      const clientWidth = scrollContainerRef.current.clientWidth;
      scrollContainerRef.current.scrollTo({
        left:scrollWidth - clientWidth,
        scrollBehavior:'smooth'
      })
    }
  }, [])
  useEffect(() => {
    if(betAmount < 0.1) setBetAmount(0.1)

  }, [betAmount])
  const updateHistory=(value)=>{
    setHistory([...history, value])
  }
  return (
      <div className="flex flex-col-reverse md:flex-row  items-center">
      <div style={{display:'fixed'}} className="flex h-auto mt-10 md:mt-0 md:h-[100vh] p-5 space-y-5 text-white font-bold left-0 flex-col bg-gray-900 ">
        <section className="bg-gray-800 space-y-6 p-4 ">
      <div className='flex flex-col justify-center  items-center'>
        
        <div className="">
            <p className='font-bold text-gray-300 p-2'>Amount</p>
            <div className='flex justify-center items-center  bg-gray-700 p-2'>
            <p className='font-bold bg-gray-600 py-1 text-gray-300'>$</p>
                <input disabled={isBetting && !cashedOut} onChange={(e) => setBetAmount(e.target.value)} value={betAmount}className='bg-gray-600 py-1' type='number'/>
                <button disabled={isBetting && !cashedOut} onClick={()=> setNewAmount('x2')} className='px-2 py-1 bg-gray-600'>x2</button>
                <button disabled={isBetting && !cashedOut} onClick={()=> setNewAmount('/2')} className='px-2 py-1 bg-gray-600'>1/2</button>
            </div>
        </div>
        </div>
        <p className='font-bold text-gray-100'>Mines</p>
      <label className="flex">
        <p>{mines}</p>
        <input disabled={isBetting && !cashedOut}
        className='w-full mx-2'
          type="range"
          value={mines}
          onChange={(e) => setMines(Number(e.target.value))}
          min={1}
          max={24} // Max mines is grid size - 1
        />
        <p>{25 - mines}</p>
      </label>
      {isBetting && !cashedOut && <button className=" font-bold text-white w-full hover:bg-purple-700 bg-purple-800  px-10 py-2" onClick={pickRandomTile} > Pick Random Tile</button>}
      <button  className="font-bold text-white w-full hover:bg-green-700 bg-green-800 text-sm px-10 py-2" onClick={isBetting && !cashedOut ? cashOut : initializeGrid}>{isBetting && !cashedOut ? `Cash Out $${cashDisplay.toFixed(2)}` : 'Bet'}</button>
      <p className="text-gray-300 py-4 p-2 bg-gray-700 text-center rounded-lg">x{payoutMultiplier}</p>
      </section>
      </div>
      <div className="mines h-[80vh] md:h-[100vh] flex flex-col w-full  items-center">
      <div ref={scrollContainerRef} style={{
        display: "flex",
        overflowX: "auto",
        scrollBehavior: "smooth",
        whiteSpace: "nowrap",
      }} className="scroll-container flex space-x-5 p-4 w-[100vw] md:w-[70.5vw] bg-gray-900 mb-10 top-0">

      {history.map((payout, index) => (<div >
        <p className={`cursor-pointer font-bold text-white ${payout === '0.00' ? 'bg-gray-600' :'bg-green-600'} rounded-lg p-4 px-6`}>x{payout}</p>
      </div>))}
      </div>
      <div className="grid grid-cols-5 shadow-lg shadow-green-900 bg-gray-900 rounded-lg p-4 w-[400px] md:w-[520px]">
        {grid.map((item, index) => (
          <button
          className={`rounded-lg m-[2px] font-bold text-5xl ${gameOver && 'opacity-25'} ${cashedOut && 'opacity-25'} ${revealed[index] ? (item === "bomb" ? "bg-red-800 " : "bg-blue-800") : "bg-gray-700"} hover:bg-gray-600 w-[70px] h-[70px] md:w-[95px] md:h-[95px]`}
            key={index}
            disabled={gameOver || cashedOut}
            onClick={() => handleClick(index, payoutMultiplier)}
          >
            {isBetting && revealed[index] && item === "diamond" ? "💎" : ""}
            {/* {revealed[index] && item === "bomb" ? "🎇" : ""} */}
            {!isBetting &&  item === "bomb" ? "🎇" : ""}
            {!isBetting &&  item === "diamond" ? "💎" : ""}

            
          </button>
        ))}
      </div>
      {gameOver && <div className="absolute mt-[300px] flex flex-col justify-center items-center p-3 bg-gray-800 rounded-lg">
        <p className="font-bold text-2xl text-red-500">Game over</p>
        <p className="font-bold  text-orange-700">You lost this round</p>
        
        <button className="m-2 font-bold text-white hover:bg-green-700 bg-green-800  px-10 py-2" onClick={isBetting ? 'cashout' : initializeGrid}>Play Again</button>
      </div>}
      {cashedOut && <CashOut 
      cashOutMultiplier={cashOutMultiplier}
       cashOutAmount={cashOutAmount} />}
      <div  className="absolute self-end flex space-x-5 p-4 w-[100vw] md:w-[70.5vw] bg-gray-900  bottom-0">
      
      </div>
      </div>
    </div>
  );
};

export default MinesGame;
