import React from 'react'
import { Link } from 'react-router-dom'
import Games from '../components/Games'
import Wins from '../components/Wins'

const Home = () => {
  return (
    <div className='p-3 flex flex-col justify-center items-center'>
        <Games/>
        <Wins/>
    </div>
  )
}

export default Home