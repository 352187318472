import React from 'react'
import MinesGames from '../components/Mines'

const Activity = () => {
  return (
    <div>
      <MinesGames/>
    </div>
  )
}

export default Activity