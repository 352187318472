import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Layout } from '../components'
import { Home, Activity, Account, Wallet, Promotion } from '../pages'
const Router = () => {
  return (
    <BrowserRouter>
      <Routes >
        <Route element={<Layout/>}>
        <Route path='/' element={<Home/>}/>
        <Route path='/activity' element={<Activity/>}/>
        <Route path='/account' element={<Account/>}/>
        <Route path='/wallet' element={<Wallet/>}/>
        <Route path='/promo' element={<Promotion/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router