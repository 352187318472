import React, { useRef, useEffect } from "react";

const Wins = () => {
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const scrollAutomatically = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollBy({ left: 2, behavior: "smooth" });

        // Loop the scroll if it reaches the end
        if (
          scrollContainerRef.current.scrollLeft + 
          scrollContainerRef.current.offsetWidth >=
          scrollContainerRef.current.scrollWidth
        ) {
          scrollContainerRef.current.scrollLeft = 0;
        }
      }
    };

    const interval = setInterval(scrollAutomatically, .0001); // Adjust speed here

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="scroll-container w-[100vw]"
      ref={scrollContainerRef}
      style={{
        display: "flex",
        overflowX: "auto",
        scrollBehavior: "smooth",
        whiteSpace: "nowrap",
      }}
    >
      {Array.from({ length: 100 }, (_, i) => (
        <div className="flex flex-col font-bold p-5 justify-between rounded-lg shadow-lg shadow-lime-600"
          key={i}
          style={{
            minWidth: "150px",
            height: "150px",
            marginRight: "10px",
            background: "orange",
            fontSize: "20px",
          }}
        >
          <p>User {i + 1}</p>
          <p className="text-sm text-green-700">${i % 6500}</p>
        </div>
      ))}
    </div>
  );
};

export default Wins;
