import React,{ useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
const Layout = () => {
    const navLinks =  [
      {
        id:1,
        title:"Home",
        link:"/",
      },{
        id:2,
        title:"History",
        link:"/activity",
      },{
        id:3,
        title:"Wallet",
        link:"/wallet",
      },{
        id:4,
        title:"Promo",
        link:"/promo",
      },{
        id:5,
        title:"Account",
        link:"/account",
      },
    ]

  const [active, setActive] = useState('')
  const [toggle, setToggle] = useState(false)
  return (
    <div className='flex flex-col'>
    <div className='flex justify-between p-2 bg-green-700'>
        <p className='text-2xl text-white font-medium'>GamesLab</p>
        <p className="text-gray-300 font-bold py-4 px-10 bg-green-900 text-center rounded-lg">Balance: $1,453</p>
        <div className='hidden md:flex p-2 space-x-4 font-bold text-green-300'>
        {navLinks.map((link) =>(
          <Link to={link.link} onClick={()=> setActive(link.title)} key={link.id} className={`${ active === link.title ? 'text-white' : 'text-green-300' } font-poppins font-medium cursor-pointer hover:text-white font-poppins`}>
            <p >{link.title}</p>
          </Link>
        ))}
        </div>
    </div>
    <Outlet/>
  </div>
  )
}

export default Layout