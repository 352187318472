import React from 'react'

const Games = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 text-white font-bold justify-between gap-4 w-full p-5 rounded-lg'>
        <div className='games p-4 rounded-lg bg-gray-600'>
            <p className='text-2xl font-bold'>Lottery</p>
            <p className='text-gray-300'>Lottery</p>
            <p className='font-bold text-sm text-white p-5 mt-7'>GamesLap</p>
        </div>
        <div className='games p-4 rounded-lg bg-gray-600'>
            <p className='text-2xl font-bold'>Keno</p>
            <p className='text-gray-300'>Keno</p>
            <p className='font-bold text-sm text-white p-5 mt-7'>GamesLap</p>
        </div>
        <div className='games p-4 rounded-lg bg-gray-600'>
            <p className='text-2xl font-bold'>Limbo</p>
            <p className='text-gray-300'>Limbo</p>
            <p className='font-bold text-sm text-white p-5 mt-7'>GamesLap</p>
        </div>
    </div>
  )
}

export default Games