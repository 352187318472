import React, { useState, useEffect, useRef } from "react";
import Bubbles from "../components/Bubbles";
import CashOut from "../components/CashOut";

const AviatorGame = () => {
  const [multiplier, setMultiplier] = useState(1.0);
  const [crashPoint, setCrashPoint] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [betAmount, setBetAmount] = useState(10);
  const [hasCashedOut, setHasCashedOut] = useState(false);
  const [earnings, setEarnings] = useState(0);
  const timerRef = useRef(null);

  // Function to start the game
  const startGame = () => {
    setMultiplier(1.0);
    setCrashPoint(generateCrashPoint());
    setIsPlaying(true);
    setHasCashedOut(false);
    setEarnings(0);
    
    let interval = 100; // Start with 100ms interval
    timerRef.current = setInterval(() => {
      setMultiplier((prev) => {
        const increment = calculateIncrement(prev);
        const newMultiplier = parseFloat((prev + increment).toFixed(2));
        
        if (newMultiplier === crashPoint) {
          endGame(true);
        }
        
        // Increase speed of multiplier as it grows
        if (newMultiplier > 1.5 && interval > 30) {
          clearInterval(timerRef.current);
          interval -= 5; // Reduce interval to make it faster
          timerRef.current = setInterval(() => setMultiplier((prev) => prev + calculateIncrement(prev)), interval);
        }

        return newMultiplier;
      });
    }, interval);
  };

  useEffect(()=>{
    setEarnings(multiplier * betAmount)

  }, [multiplier])
  // Generate a random crash point
  const generateCrashPoint = () => {
    return parseFloat((Math.random() * 5 + 1).toFixed(2)); // Random between 1.00 and 10.00
  };

  // Calculate the increment based on the current multiplier
  const calculateIncrement = (currentMultiplier) => {
    if (currentMultiplier < 2) return 0.02;
    if (currentMultiplier < 5) return 0.05;
    return 0.1; // Larger increments for higher multipliers
  };

  // Handle cash out
  const cashOut = () => {
    if (!isPlaying || hasCashedOut) return;
    setHasCashedOut(true);
    // setEarnings(parseFloat((betAmount * multiplier).toFixed(2)));
    endGame(true);
  };

  // End the game
  const endGame = (isWin) => {
    clearInterval(timerRef.current);
    setIsPlaying(false);
    if (!isWin) {
      alert(`Crashed at ${crashPoint.toFixed(2)}x! You lost.`);
    } else {
      alert(`Cashed out at ${multiplier.toFixed(2)}x! You won ${earnings.toFixed(2)} coins.`);
    }
  };

  // Reset game state
  const resetGame = () => {
    setMultiplier(1.0);
    setCrashPoint(0);
    setIsPlaying(false);
    setHasCashedOut(false);
    setEarnings(0);
    clearInterval(timerRef.current);
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current); // Cleanup on component unmount
  }, []);

  return (
    <div className="flex flex-col justify-center items-center p-6">
      <CashOut 
      cashOutMultiplier={multiplier.toFixed(2)}
       cashOutAmount={earnings} />
      <div className="flex flex-col justify-center items-center crash-container shadow-lg shadow-green-900  rounded-lg p-4 h-[400px] md:h-[520px]  w-[400px] md:w-[920px]">
      <Bubbles/>
      
      <p className="text-5xl p-2 text-gray-100 font-bold">{multiplier.toFixed(2)}x</p>
      <p className="text-green-500 text-center rounded-lg w-[12vw] px-6 p-2 bg-gray-900 font-bold">${earnings.toFixed(2)}</p>
      </div>
      <h1>Aviator Game</h1>
      
      <p>Crash Point: {crashPoint > 0 ? crashPoint.toFixed(2) + "x" : "N/A"}</p>
      

      <label>
        Bet Amount:
        <input
          type="number"
          value={betAmount}
          onChange={(e) => setBetAmount(parseFloat(e.target.value))}
          min={1}
        />
      </label>

      {!isPlaying ? (
        <button onClick={startGame}>Start Game</button>
      ) : (
        <button onClick={cashOut} disabled={hasCashedOut}>
          Cash Out
        </button>
      )}

      {isPlaying && hasCashedOut && (
        <button onClick={resetGame}>Start New Round</button>
      )}
      
    </div>
  );
};

export default AviatorGame;
